import Busca from "../../components/busca/busca.jsx";
import Menu from "../../components/menu/menu.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import NegocioCad from "../negocio-cad/negocio-cad.jsx";
import api_horse from "../../services/api_horse.js";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/index.jsx";
import "./pacientepre.css";
import PacientePreModal from "../../components/pacientepre/sanos/modal/index.jsx";

function PrePaciente() {

    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dados, setDados] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);

    const [pacientepre, setPacientepre] = useState([]);
    const [isPacientePreOpen, setIsPacientePreOpen] = useState(false);
    const [dados_pacientepre, setDados_Pacientepre] = useState({ idcontato: 0 });
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [busca, setBusca] = useState("");


    const handleInputChange = (event) => {
        setBusca(event.target.value);
    };

    const handleSearchClick = () => {
        const idEmpresa = localStorage.getItem("id_empresa");
        ListarNegocios(idEmpresa, busca, 1, qtd_reg_pagina);
    };

    const columns = [
        {
            name: 'Código',
            selector: row => row.id,
            sortable: true,
            width: "120px",
        },
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
            compact: true
        },
        {
            name: 'CPF',
            selector: row => row.cpf,
            sortable: true
        },
        {
            name: 'Empresa',
            selector: row => <>
                <p className="mb-1">{row.empresa}</p>
                <p className="mb-1">Cidade: {row.cidade}</p>
            </>,
            sortable: true,
            compact: true
        },
        {
            name: 'Dados Contato',
            selector: row => <>
                <p className="mb-1">E-mail: {row.email}</p>
                <p className="mb-1">Fone: {row.telefone}</p>
            </>,
            sortable: true
        },
        {
            cell: (row) => <>
                <button onClick={() => OpenModalPacientePre(row.id)} className="btn btn-primary ms-3"><i className="bi bi-pencil-square"></i></button>
                <button onClick={() => OpenModalPacientePre(0)} className="btn btn-danger ms-3"><i className="bi bi-trash3-fill"></i></button>

            </>,
            width: "200px",
            right: true
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos'
    };

    function Excluir(id) {
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão do negócio?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api_horse.delete("/negocios/" + id)
                            .then((resp) => {
                                ListarNegocios(localStorage.getItem("id_empresa"), busca, 1, qtd_reg_pagina);
                            })
                            .catch((err) => {
                                alert("Erro ao excluir negócio");
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }



    function ListarNegocios(empresa, etapa, pagina, qtd_reg_pagina) {


        api_horse.get("/v1/pacientepre/listar", { params: { empresa, etapa, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar pré-paciente");
            });

    }


    function ChangePerRows(newQtd, page) {
        ListarNegocios(localStorage.getItem("id_empresa"), busca, page, newQtd);
        setQtdRegPagina(newQtd);
    }

    function ChangePage(page) {
        ListarNegocios(localStorage.getItem("id_empresa"), busca, page, qtd_reg_pagina);
    }

    function RefreshDados() {
        ListarNegocios(localStorage.getItem("id_empresa"), busca, 1, qtd_reg_pagina);
    }

    useEffect(() => {

        ListarNegocios(localStorage.getItem("id_empresa"), busca, 1, qtd_reg_pagina);

    }, []);


    function OpenModalPacientePre(id) {

        console.log(id)
        if (id > 0) {
            api_horse.get(`/v1/pacientepre/${id}`)
                .then(response => {
                    setDados_Pacientepre(response.data[0]);
                    setIsPacientePreOpen(true);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            setDados_Pacientepre({
                id: 0, telefone: "", dor: "", nivel_interesse: "", nome: "", cpf: "", medico: "", obs: "", avaliado_por_medico: "",
                tipoavaliacao: "", nivel_interesse: "", tecnica: "", desconto_por: "", valor_venda: 0, email: "", apelido: ""
            });
            setIsPacientePreOpen(true);
        }
    }

    function closeModalPacientePre() {
        setIsPacientePreOpen(false);
        setLoading(false);
        ListarNegocios(localStorage.getItem("id_empresa"), busca, 1, qtd_reg_pagina);
    }

    return <>

        <NegocioCad onClose={RefreshDados} />

        <div className="container-fluid mt-page">

            <Navbar />

            <PacientePreModal isOpen={isPacientePreOpen}
                dados_pacientepre={dados_pacientepre}
                onRequestClose={closeModalPacientePre}
            />

            <div className="row flex-nowrap">
                <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
                    <Menu page="negocios" />
                </div>

                <div className="col py-3 me-3">


                    <div className="bg-white p-4 rounded-4 border">

                        <div className="d-flex justify-content-between mb-3">
                            <div className="d-inline-flex">
                                <h2>Pré-Pacientes</h2>

                            </div>

                            <div className="mb-8 me-3">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Busca Pré-Paciente"
                                        value={busca}
                                        onChange={handleInputChange}
                                    />
                                    <button
                                        onClick={handleSearchClick}
                                        className="btn btn-primary me-3"
                                        type="button"
                                        id="button-addon2"
                                    >
                                        Busca Pré-Paciente
                                    </button>
                                </div>
                            </div>

                            <button onClick={() => OpenModalPacientePre(0)} className="btn btn-primary ms-4"><i className="bi bi-plus-lg me-2"></i>Novo Pré-Paciente</button>
                        </div>

                        <DataTable columns={columns}
                            data={dados}
                            pagination={true}
                            paginationComponentOptions={paginationOptions}
                            noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}

                            paginationServer={true}
                            paginationTotalRows={total_registros}
                            onChangeRowsPerPage={ChangePerRows}
                            onChangePage={ChangePage}
                        />

                    </div>


                    <div className="row">


                    </div>

                </div>
            </div>
        </div>
    </>
}


export default PrePaciente;