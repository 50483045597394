import "./login.css";
import logo from "../../assets/logo.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api.js";
import api_horse from "../../services/api_horse.js";
import SaltPassword from "../../services/md5.js";

function Login(){

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [erro, setErro] = useState("");
    const [loading, setLoading] = useState(false);

    function ProcessaLogin(){

        setLoading(true);

        const params = {
            email,
            senha
        };

        api_horse.post(`/v1/usuarios/login`, {
            email,
            senha,
            senha2: SaltPassword(senha)                        
            })
        .then((resp) => {
            setLoading(false);
           
            if (resp.data.status == "success")
            {
                const defaultEmpresa = resp.data.user.empresas.find(empresa => empresa.default === 1);
                const defaultEmpresaId = defaultEmpresa ? defaultEmpresa.id : null;
                const defaultEmpresaNome = defaultEmpresa ? defaultEmpresa.nome : null;
                localStorage.setItem('sessionToken', resp.data.token2);
                localStorage.setItem("id_usuario", resp.data.user.id);
                localStorage.setItem("id_empresa", defaultEmpresaId);
                localStorage.setItem("empresa", defaultEmpresaNome);
                localStorage.setItem("usuario", resp.data.user.email);
                navigate("/dashboard");
            } else {
                setErro("Erro ao realizar login");
            }

        })
        .catch((err) => {
            setLoading(false);

            if (err.response?.data.erro) {
                setErro(err.response.data.erro);
            } else {
                setErro("Erro ao realizar login");
            }
        })
    }

    return <div className="d-flex justify-content-center align-items-center content mt-5">
        <form className="form-signin">
            <div className="text-center">
                    <img className="mb-5" src={logo} alt=""  />
            </div>
            <h3 className="mb-3">Login</h3>
           
            <div className="form-floating">
                <input type="email" className="form-control" id="floatingInput" onChange={(e) => setEmail(e.target.value)} />
                <label for="floatingInput">Login</label>
            </div>
            <br />
            <div className="form-floating">
                <input type="password" className="form-control" 
                        id="floatingPassword" onChange={(e) => setSenha(e.target.value)} />
                <label for="floatingPassword">Senha</label>
            </div>
            <br />            

            {
                loading ? 
                    <span className="btn btn-primary w-100 py-2 disabled">
                        <div class="spinner-border spinner-border-sm text-light me-2" role="status"></div>
                        Acessando...
                    </span>
                    
                : <button onClick={ProcessaLogin} className="btn btn-primary w-100 py-2" type="button">Acessar</button>
            }
            

            {
                erro.length > 0 ?
                <div class="alert alert-danger mt-4 text-center" role="alert">{erro}</div>                        
                : null
            }

            <div className="text-center">
                <p className="mt-5 mb-3 text-secondary">Suporte - INFOTECJF SISTEMAS</p>
            </div>    
        </form>
    </div>
}

export default Login;